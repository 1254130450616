import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap'
import { FormikInputWithLabel, FormikErrorText, Theme, Button } from '@pga/pga-component-library'
import { Formik } from 'formik'
import moment from 'moment-timezone'

const FlexRow = styled.div`
  display: flex;
  @media(max-width: 575.98px) {
    flex-direction: column;
  }
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media(max-width: 575.98px) {
    margin-top: 1rem;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

const FlexColMargin = styled(FlexCol)`
  margin-right: 1rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: ${Theme.colors.lightBlue};
    @media(max-width: 575.98px) {
      margin-top: 1rem;
    }
  }
  button {
    background-color: ${Theme.colors.lightBlue} !important;
    border-color: ${Theme.colors.lightBlue} !important;
  }
  @media(max-width: 575.98px) {
    flex-direction: column;
  }
`

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  memberNumber: ''
}

const requiredFieldNames = ['firstName', 'lastName', 'memberNumber']

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required!'
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address.'
  }
  for (const key in values) {
    const value = values[key]
    if (!value && requiredFieldNames.includes(key)) {
      errors[key] = 'Required!'
    }
  }

  return errors
}

const HOOK_POST_URL = 'https://hooks.zapier.com/hooks/catch/4140728/ogn4b58/'

const onSubmit = (values, { setSubmitting }) => {
  if (typeof window !== 'undefined') {
    const { firstName, lastName, email, memberNumber } = values
    const payload = {
      firstName,
      lastName,
      email,
      memberNumber,
      submittedTimestamp: moment().tz('America/New_York').format('h:mm a')
    }
    const options = {
      body: JSON.stringify(payload),
      method: 'POST',
      mode: 'cors'
    }
    window.fetch(HOOK_POST_URL, options)
      .finally(() => {
        setSubmitting(false)
        window.location.assign('/annual-meeting-livestream')
      })
  }
}

export const Form = () => (
  <Formik
    initialValues={initialValues}
    validate={validate}
    onSubmit={onSubmit}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    }) => (
      <form onSubmit={handleSubmit}>
        <Container>
          <FlexRow>
            <FlexColMargin>
              <FormikInputWithLabel
                label='First Name'
                type='text'
                name='firstName'
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                size='large'
                fw
              />
              <FormikErrorText {...{ errors, touched, field: 'firstName' }} />
            </FlexColMargin>

            <FlexCol>
              <FormikInputWithLabel
                label='Last Name'
                type='text'
                name='lastName'
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                size='large'
                fw
              />
              <FormikErrorText {...{ errors, touched, field: 'lastName' }} />
            </FlexCol>
          </FlexRow>

          <FlexRow className='mt-4'>
            <FlexColMargin>
              <FormikInputWithLabel
                label='Email Address'
                type='email'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                size='large'
                fw
              />
              <FormikErrorText {...{ errors, touched, field: 'email' }} />
            </FlexColMargin>

            <FlexCol>
              <FormikInputWithLabel
                label='Member/Associate/Student Number'
                type='number'
                name='memberNumber'
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                size='large'
                fw
              />
              <FormikErrorText {...{ errors, touched, field: 'memberNumber' }} />
            </FlexCol>
          </FlexRow>

          <ButtonWrapper className='mt-4'>
            <Button type='submit' disabled={isSubmitting}>
              Submit
            </Button>
            <a href='/annual-meeting-livestream'>I dont need MSRs. Take me to the livestream ></a>
          </ButtonWrapper>
        </Container>
      </form>
    )}
  </Formik>
)

export const StyledModal = styled(Modal)`
  @media (min-width:  576px) {
    max-width: 750px !important;
  }
`
const ModalTitle = styled.div`
  margin: 10px 0px 10px 10px;
  color: ${Theme.colors.primary};
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.09;
  letter-spacing: -0.3px;
  font-family: ${Theme.fonts.PlayfairDisplay};
`

export const ModalWrapper = ({ modalState, toggleModalState }) => (
  <StyledModal isOpen={modalState} toggle={toggleModalState}>
    <ModalHeader toggle={toggleModalState}>
      <ModalTitle>Annual Meeting Attendee Information:</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <Form />
    </ModalBody>
  </StyledModal>
)

export default () => {
  const [modalState, setModalState] = useState(true)
  const toggleModalState = () => setModalState(!modalState)
  return (
    <ModalWrapper {...{ modalState, toggleModalState }} />
  )
}
